import React, { useEffect, useRef } from 'react'
import { useId } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, setDsdService, setInitForm, setReceivingMerchantCode, setReceivingMerchantName } from '../../../slices/merchant-payment-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import { showToast } from '../../../slices/toast-slice';
import ReactSelect from 'react-select';

export default function SearchMerchantCode() {
    const id = useId();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const dispatch = useDispatch();

    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const current_step = useSelector(state => state.merchantPayment.current_step);
    const initForm = useSelector(state => state.merchantPayment.initForm);
    const [tmp_merchantCode, setTmpMerchantCode ] = useState("");
    const dsdService = useSelector(state => state.merchantPayment.dsdService);
    const [availableServices, setAvailableServices ] = useState([]);
    const selectInputRef = useRef();

    useEffect(() => {
       fetchServices();
      }, []);

    const handleSearch = () => {
        // start the loading actiity

        if(tmp_merchantCode == "") {
            dispatch(showToast(['danger', '', 'Merchant code is required']));
            return;
        }

        selectInputRef.current.clearValue()

        dispatch(showLoading())

        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/searchMerchantCode`;
        const country_code = globalAuthUser.location.agent_country.sortname;

        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                "merchant_code": tmp_merchantCode,
                "country_code": country_code
            }
        }).then(res => {
            const response = res.data;
            const resData = response?.data;

            if(response?.success){
                // makes sure the list is not empty
                if(!resData.length){
                    dispatch(showToast(['danger', 'search Error', 'No merchant found with this code']));
                    dispatch(hideLoading());
                    return;
                }

                // extract merchat details
                const merchantDetails = resData[0];
                console.log(merchantDetails);

                if(!merchantDetails?.can_accept_cash_deposit) {
                    // merchant cannot accept cash deposit
                    dispatch(showToast(['danger', 'Payment Error', 'Merchant cannot accept cash deposit']));
                    dispatch(hideLoading());
                    return;
                }

                // set the receiving merchant name and code
                dispatch(setReceivingMerchantCode(merchantDetails?.code));
                dispatch(setReceivingMerchantName(merchantDetails?.name));
                dispatch(setCurrentStep(1));
                let val = initForm + 1;
                dispatch(setInitForm(val));

                dispatch(hideLoading());
                return;
            }

            // something went wrong
            const message = response?.message
            dispatch(hideLoading());
            dispatch(showToast(['danger', 'Search Error', message]));

        }).catch(err => {
            console.log(err);

            dispatch(hideLoading());
            dispatch(showToast(['danger', 'Search Error', err]));
        })

    }

    function fetchServices() {
        console.log('inside fetchservices')
        if(globalAuthUser.location.agent_country.isoname == 'GIN') {
            console.log('inside fetchservices 1')
            axios({
                method: "get",
                url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/merchant/avalaibleServices",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `${authUser().tokenType} ${authUser().token}`,
                },
            })
                .then((res) => {
                    dispatch(hideLoading());
                    if (res.data.success === true) {
                        setAvailableServices(res.data.data);
                    } else {
                        dispatch(showToast(["danger", "", res.data.message]));
                    }
                })
                .catch((error) => {
                    dispatch(hideLoading());
                    dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                });
        }
    }

    const onServiceChange = (event) => {
        if(event != null) {
            console.log(event)
            dispatch(setDsdService(event));
            dispatch(setReceivingMerchantCode(event.merchantCode));
            dispatch(setReceivingMerchantName(event.merchantName));
            dispatch(setCurrentStep(1));
            let val = initForm + 1;
            dispatch(setInitForm(val));
            document.getElementById("mPlateNumber").value = "";
        }
    }

  return (
        <>

            <div className='row justify-content-center' style={{ maxWidth: "800px", margin: "auto"}}>

                {
                  globalAuthUser.location.agent_country.isoname == 'GIN' ?
                  <>
                      <div style={{ textAlign: 'center', marginBottom: "20px", fontWeight: '400' }}>
                          <span> {t('SEARCH_MERCHANT_TEXT')} </span>
                      </div>
                      <div className='col-md-6'>
                          <div className="mb-3">
                              <label htmlFor="mPlateNumber" className="form-label" > {t("MERCHANT_CODE")} </label>
                              <div>
                                  <input type="text" className="form-control" id="mPlateNumber" style={{ display: "inline-block", width: '250px', marginRight: '15px' }} onChange={(e) => setTmpMerchantCode(e.target.value)} />
                                  <button type='button' className="btn btn-sm btn-block btn-primary" style={{ display: "inline-block" }} onClick={() => handleSearch()}> {t("SEARCH")} </button>
                              </div>

                              {/* <p className="input-error-msg"> {errors.mPlateNumber?.message} </p> */}
                          </div>
                      </div>
                      <div className='col-md-6' style={{ borderLeft: "1px #333 solid" }}>
                          <div className="mb-3" style={{ marginLeft: "30px" }}>
                              <label htmlFor="documentType" className="form-label" > {t("AVAILABLE_SERVICES")} </label>
                              <ReactSelect
                              ref={selectInputRef}
                                id="serviceField"
                                  placeholder={t("SELECT")}
                                  styles={{
                                      control: (baseStyles, state) => ({
                                          ...baseStyles, border: "2px solid #ced4da",
                                            //   borderColor: state.isFocused ? 'grey' : 'red',
                                          padding: '2px', borderBottomLeftRadius: "0px", borderBottomLeftRadius: "0px"
                                      }),
                                  }}
                                  options={availableServices}
                                  formatOptionLabel={(service) => (
                                      <div>
                                          <span> {service.serviceDesc} </span>
                                      </div>
                                  )}
                                  getOptionValue={service => `${service.serciveCode}`}
                                  getOptionLabel={service => `${service.serviceDesc}`}
                                  onChange={onServiceChange}
                              />
                          </div>
                      </div>
                  </>
                  :
                      <div className="row mb-3 border-bottom">
                          <div className="col-12 col-lg-3"></div>
                          <div className="col-12 col-lg-6">

                              <div className="mb-1">
                                  <label htmlFor="mPlateNumber">
                                      {t("MERCHANT_CODE")}
                                  </label>
                                  <input type="text" name="" id="mPlateNumber" className="form-control"
                                      value={tmp_merchantCode} onChange={(e) => setTmpMerchantCode(e.target.value)} />
                              </div>

                              <div className="mb-4 d-grid">
                                  <button type='button' className="btn btn-sm btn-block btn-primary" onClick={() => handleSearch()}>
                                      {t("SEARCH")}
                                  </button>
                              </div>

                          </div>
                      </div>
                }

              
            </div>

            
        </>
  )
}
